import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import UserStore from '../user/UserStore';
import { BomData, BomMetloads, MatchedBomStation, Station } from './BomUtils';
import {
  GetStationElementsResponse,
  GetStationsResponse
} from './model/BomAPI';
import { BomElement } from './model/BomElement';
import { BomStation } from './model/BomStations';

export class BomStore {
  // Stores.
  userStore: UserStore;
  stationStore: StationStore;

  bomStations: BomStation[] = [];
  bomData: BomData = {
    stations: []
  };
  bomStationMetloads: BomMetloads[] = [];
  bomMatchedStations: MatchedBomStation[] = [];

  selectedStationIndex: number | undefined;

  loadingBomStations = false;
  loadingAPIStations = false;
  loadingStationElements = false;
  loadingBomStationMetloads = false;
  loadingRemoveElement = false;
  loadingAddElement = false;
  loadingSetupBomStation = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  async loadStations() {
    runInAction(() => {
      this.loadingBomStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/bom-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as BomStation[];
      runInAction(() => {
        this.bomStations = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingBomStations = false;
    });
  }

  async loadAPIStations() {
    runInAction(() => {
      this.loadingAPIStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/sftp/get-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationsResponse;
      runInAction(() => {
        const stations: Station[] = data.map((station) => {
          return {
            ...station,
            elements: []
          };
        });
        this.bomData.stations = stations;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAPIStations = false;
    });
  }

  async loadStationElements(stationId: string) {
    runInAction(() => {
      this.loadingStationElements = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/sftp/get-station-elements?station_id=${stationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationElementsResponse;
      const stationIndex = this.bomData.stations.findIndex(
        (station) => station.wmo_id === stationId
      );
      this.bomData.stations[stationIndex].elements = data;
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingStationElements = false;
    });
  }

  async loadBomStationMetloads(bomStation: BomStation) {
    runInAction(() => {
      this.loadingBomStationMetloads = true;
    });
    try {
      console.log(`${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/bom/metloads/${bomStation.id}`);
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/bom/metloads/${bomStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as BomMetloads[];
      runInAction(() => {
        this.bomStationMetloads = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingBomStationMetloads = false;
    });
  }

  async removeElement(
    bomStationId: number,
    bomElementId: number,
    elementId: string,
    measurementTypeId: string
  ) {
    runInAction(() => {
      this.loadingRemoveElement = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/remove-element/${bomStationId}/${bomElementId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            variable_id: elementId,
            measurement_type_id: measurementTypeId
          })
        }
      );
      if (!response?.ok) throw Error('request failed');
      const data = (await response?.json()) as BomStation;
      runInAction(async () => {
        const bomStationIndex = this.bomStations.findIndex(
          (hs) => hs.id === bomStationId
        );
        this.bomStations = this.bomStations.map((ms, i) => {
          if (i !== bomStationIndex) return ms;
          return data;
        });
        if (this.selectedStation) {
          const station = { ...this.selectedStation };
          station.network = data;
          await this.getMetloadsMeasurements(station);
          this.updateStation(station);
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error(error);
    }
    runInAction(() => {
      this.loadingRemoveElement = false;
    });
  }

  async addElement(
    bomStationId: number,
    body: Pick<BomElement, 'name' | 'units'> & {
      variable_id: string;
      measurement_type_id: string;
      multiplier: number;
      offset: number;
    }
  ): Promise<BomStation | undefined> {
    runInAction(() => {
      this.loadingAddElement = true;
    });
    let data: BomStation | undefined;
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/add-element/${bomStationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      if (!response?.ok) return undefined;
      const bomStationData = (data = (await response?.json()) as BomStation);
      runInAction(() => {
        const bomStationIndex = this.bomStations.findIndex(
          (hs) => hs.id === bomStationId
        );
        this.bomStations = this.bomStations.map((hs, i) => {
          if (i !== bomStationIndex) return hs;
          return bomStationData;
        });
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAddElement = false;
    });
    return data;
  }

  async setupBomStation(
    station: Station & {
      bom_wmo_id: string;
      bom_network_setup: 0 | 1;
    }
  ) {
    runInAction(() => {
      this.loadingSetupBomStation = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/bom/setup-bom-station`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(station)
        }
      );
      const data = (await response?.json()) as BomStation;
      runInAction(() => {
        this.stationStore.addStation(data.station);
        this.bomStations.push(data);
        const i = this.bomMatchedStations.findIndex(
          (s) => s.station?.wmo_id.toString() === station.bom_wmo_id
        );
        const matchedMetserviceStation = this.bomMatchedStations[i];
        if (matchedMetserviceStation) {
          matchedMetserviceStation.network = data;
          const newMatchedMetserviceStations = [...this.bomMatchedStations];
          newMatchedMetserviceStations[i] = matchedMetserviceStation;
          const sortedMatched = this.sortMatchedBomStations(
            newMatchedMetserviceStations
          );
          this.bomMatchedStations = sortedMatched;
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingSetupBomStation = false;
    });
  }

  /**
   * Match stations and stations by the stations ID.
   * Each station should be assigned to one station.
   * Not all stations will be assigned a station.
   */
  getMatchedBomStations() {
    const stations = this.bomStations;
    const networks = this.bomData.stations;

    let matched: MatchedBomStation[] = [];
    networks.forEach((station: Station) => {
      const networkId = station.wmo_id;
      stations.forEach((network: BomStation) => {
        const wmoId = network.wmo_id;
        if (networkId === wmoId) {
          matched.push({
            station,
            network
          });
        } else {
          matched.push({
            station
          });
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          station
        });
      }
    });
    matched = this.sortMatchedBomStations(matched);
    runInAction(() => {
      this.bomMatchedStations = matched;
    });
  }

  sortMatchedBomStations(matched: MatchedBomStation[]) {
    return matched
      .filter((matched) => {
        const matchedStation = matched.station;
        const stations = this.bomData.stations;
        return stations.some(
          (station) => station.wmo_id === matchedStation.wmo_id
        );
      })
      .sort((a, b) => {
        const idA = a.network ? a.network.station_id : undefined;
        const idB = b.network ? b.network.station_id : undefined;
        if (!idA && idB) return 1;
        else if (!idB && idA) return -1;
        else if (!idA || !idB) return 0;
        return 0;
      })
      .filter((value, index, self) => {
        return (
          index ===
          self.findIndex((t) => t.station.wmo_id === value.station.wmo_id)
        );
      });
  }

  updateStation(station: MatchedBomStation) {
    const i = this.findStationIndex(station);
    if (i !== undefined) {
      const newMatchedBomStation = [...this.bomMatchedStations];
      newMatchedBomStation[i] = station;
      this.bomMatchedStations = this.sortMatchedBomStations(
        newMatchedBomStation
      );
    }
  }

  async getMetloadsMeasurements(matched: MatchedBomStation) {
    const currentStation = matched.network;
    if (!matched || !matched.station) {
      return;
    }
    // Load site measurements.
    await this.loadStationElements(matched?.station.wmo_id);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadBomStationMetloads(currentStation);
      currentStation.metloadWithElements = this.bomStationMetloads;
    }
  }

  deSelectStation() {
    this.selectedStationIndex = undefined;
  }

  findStationIndex(station: MatchedBomStation): number | undefined {
    const i = this.bomMatchedStations.findIndex((s) =>
      station.station && s.station
        ? station.station.wmo_id === s.station.wmo_id
        : station.network && s.network
        ? station.network.station_id === s.network.station_id
        : false
    );
    return i > -1 ? i : undefined;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.station
      ? true
      : false;
  }

  set setSelectedStationIndex(station: MatchedBomStation) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  get selectedStationElements() {
    return this.bomData.stations.find(
      (g) => g.wmo_id === this.selectedStation?.station.wmo_id
    )?.elements;
  }

  get selectedStation(): MatchedBomStation | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.bomMatchedStations[this.selectedStationIndex]
      : undefined;
  }

  get loading() {
    return (
      this.loadingBomStations ||
      this.loadingAPIStations ||
      this.loadingStationElements ||
      this.loadingSetupBomStation ||
      this.loadingAddElement ||
      this.loadingRemoveElement
    );
  }

  get loadingInitial() {
    return this.loadingBomStations || this.loadingAPIStations;
  }
}

